/** 图形转换相关函数 */
import { PointType, PointUpperCase } from '@/partTypes'

/**
 * @param {object | array} data 将点位{X,Y}转换为{x, y}
 * @returns
 */
export function toLowerCasePoint(data: PointUpperCase | PointUpperCase[]) {
  return Array.isArray(data)
    ? data.map((item) => {
        return {
          x: item.X,
          y: item.Y,
        }
      })
    : {
        x: data.X,
        y: data.Y,
      }
}
/**
 * @param {object | array} data 将点位{x, y}转换为{X, Y}
 * @returns
 */
export function toUpperCasePoint(data: PointType | PointType[]) {
  return Array.isArray(data)
    ? data.map((item) => {
        return {
          X: item.x,
          Y: item.y,
        }
      })
    : {
        X: data.x,
        Y: data.y,
      }
}
