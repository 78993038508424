import { buryPoint } from '@/apis/common/buryPoint'
import store from '@/store'
import { toDecimal, uniquePlankNum } from '@/util/commonFuncs'
import { generateSimplePlankNum } from '@/util/commonFuncs'
import { dealPlankPoly, getPlateKnifeDiameter } from '@/util/dealPaibanData.js'
import { calcBigpartArea, calcCutPositionOrder } from '@/util/drawPlankFuncs.js'
import { cloneDeep } from 'lodash'
import { nanoid } from 'nanoid'

import { cutKnifeRadius } from './LayoutFuncs.js'
import ClipperLib from './clipper_unminified.js'
import { dealNumber } from './commonFuncs.js'
import { expandPath } from './dealPlankDataFuncs.ts'

let ncSetting = store.state.ncSetting
let userInfo = {}
let gap = 0.1
let edge = 0
let edgeFront = 0
let edgeBack = 0
let edgeLeft = 0
let edgeRight = 0
// 最大余料矩形裁剪
export function surplusMaxtailor(stockPlank, nc_setting, user_info) {
  ncSetting = nc_setting ? nc_setting : ncSetting
  gap = nc_setting ? nc_setting.panelSize.layoutGap : 0.1
  // 满足使用锯片 优先使用锯片刀
  const isUseSaw = stockPlank.parts.every((part) => part.isUseSaw)
  let diameter = isUseSaw
    ? cutKnifeRadius * 2
    : +getPlateKnifeDiameter(stockPlank.stockKey, nc_setting)
  // 玻璃切割机间隙为0 刀径为0
  if (nc_setting.glass_setting) {
    gap = 0.01
    diameter = 0
  }
  userInfo = user_info ? user_info : userInfo
  if (stockPlank.surplusInfo && stockPlank.surplusInfo.shape == 'lshape') return
  let diameterR = diameter / 2
  if (stockPlank.margin.toString().includes(',')) {
    const edgeArr = stockPlank.margin.toString().split(',')
    edgeFront = Number(edgeArr[0]) - diameterR
    edgeBack = Number(edgeArr[1]) - diameterR
    edgeLeft = Number(edgeArr[2]) - diameterR
    edgeRight = Number(edgeArr[3]) - diameterR
  } else {
    edgeFront = Number(stockPlank.margin) - diameterR
    edgeBack = Number(stockPlank.margin) - diameterR
    edgeLeft = Number(stockPlank.margin) - diameterR
    edgeRight = Number(stockPlank.margin) - diameterR
  }
  const stock_width = stockPlank.plankWidth - edgeRight
  const stock_height = stockPlank.plankHeight - edgeBack
  let parts = stockPlank.parts
  let part_rects = []
  for (let part of parts) {
    const p_x = part.startX
    const p_y = part.startY
    const p_w = part.rect.width
    const p_h = part.rect.height
    let p_rect = [p_x, p_y, p_x + p_w, p_y + p_h]
    part_rects.push(p_rect)
  }
  let all_rect = new Set()
  all_rect.add([edgeLeft, edgeFront, stock_width, stock_height])
  for (let rect of part_rects) {
    put_rect_in_zone(rect, all_rect, [])
  }
  // all_rect所有矩形
  let maxArea = 0
  let maxRect = null

  for (let rect of all_rect) {
    let curArea = (rect[2] - rect[0]) * (rect[3] - rect[1])
    // 如果当前矩形满足条件再考虑最大矩形
    let curRect = {
      width: rect[2] - rect[0] - diameter,
      height: rect[3] - rect[1] - diameter,
    }
    const currentSideObj = judgeLongOrShort(curRect.width, curRect.height)
    const targetSideObj = judgeLongOrShort(
      store.state.shortSideRect,
      store.state.longSideRect
    )
    let currentShortSide = currentSideObj.shortSide,
      currentLongSide = currentSideObj.longSide,
      targetShortSide = targetSideObj.shortSide,
      targetLongSide = targetSideObj.longSide
    if (
      (currentShortSide >= targetShortSide &&
        currentLongSide >= targetLongSide) ||
      currentShortSide >= targetLongSide
    ) {
      if (curArea > maxArea) {
        maxArea = curArea
        maxRect = rect
      }
    }
  }
  if (maxRect) {
    maxRect = {
      startX: maxRect[0],
      startY: maxRect[1],
      width: maxRect[2] - maxRect[0],
      height: maxRect[3] - maxRect[1],
    }
    const checklistID = dealChecklistID(parts)
    const newPlank = addNewSurplus(
      maxRect,
      stockPlank,
      'notcare',
      checklistID,
      isUseSaw
    )
    // 清空搜集的plankNum
    // 更新顶部信息（优化率）
    calcBigpartArea(stockPlank, ncSetting)
    dealPlankPoly(newPlank)
    surplusMaxtailor(stockPlank, nc_setting, user_info)
  }
}
function judgeLongOrShort(width, height) {
  let shortSide, longSide
  if (width > height) {
    longSide = width
    shortSide = height
  } else if (width == height) {
    longSide = shortSide = width = height
  } else {
    longSide = height
    shortSide = width
  }
  return {
    shortSide,
    longSide,
  }
}
// 添加一个新的余料
function addNewSurplus(rect, plank, texDir, checklistID, isUseSaw) {
  let diameter = isUseSaw
    ? cutKnifeRadius * 2
    : +getPlateKnifeDiameter(plank.stockKey, ncSetting)
  // 玻璃切割机刀径为0
  if (ncSetting.glass_setting) {
    diameter = 0
  }
  let newPlank = {
    startX: rect.startX,
    startY: rect.startY,
    rect: {
      x: 0,
      y: 0,
      width: rect.width,
      height: rect.height,
    },
    realRect: {
      x: 0,
      y: 0,
      width: rect.width - diameter,
      height: rect.height - diameter,
    },
    oRect: {
      x: 0,
      y: 0,
      width: rect.width - diameter,
      height: rect.height - diameter,
    },
    fullSize: {
      x: 0,
      y: 0,
      width: rect.width - diameter,
      height: rect.height - diameter,
    },
    realCurve: {},
    edgeInfo: '←0↓0→0↑0',
    holes: [],
    slots: [],
    sholes: [],
    sslots: [],
    specialType: 'supplus',
    matCode: plank.matCode,
    thick: Number(plank.thick),
    texDir,
    texture: plank.texture,
    address: '',
    name: '余料板件',
    stockNum: plank.stockNum,
    stockKey: plank.stockKey,
    plankWidth: ncSetting.drawPlankWidth,
    plankHeight: ncSetting.drawPlankHeight,
    checklistID,
    isUseSaw,
    srcTexDir: 'notcare',
  }
  if (ncSetting.glass_setting && ncSetting.glass_setting.contour_size_recoup) {
    const size = ncSetting.glass_setting.contour_size_recoup
    newPlank.oRect.width -= size * 2
    newPlank.oRect.height -= size * 2
    newPlank.fullSize.width -= size * 2
    newPlank.fullSize.height -= size * 2
    newPlank.realRect.width -= size * 2
    newPlank.realRect.height -= size * 2
  }
  let maxPlankId = Math.max(
    ...plank.parts.map((item) => (+item.plankID ? item.plankID : -999))
  )
  if (maxPlankId == -999) maxPlankId = 0
  newPlank.plankID = maxPlankId + 1
  // 柜门跳转需要添加doorsize和siez
  if (window.sessionStorage.getItem('thinkerx_material')) {
    newPlank.doorSize = `${newPlank.oRect.height}*${newPlank.oRect.width}*1`
    newPlank.size = `${newPlank.rect.height}*${newPlank.rect.width}*1`
  }
  newPlank.surplusPath = [
    [
      { x: 0, y: 0 },
      { x: rect.width, y: 0 },
      { x: rect.width, y: rect.height },
      { x: 0, y: rect.height },
    ],
  ]
  newPlank.realCurve = [
    { x: 0, y: 0 },
    { x: newPlank.oRect.width, y: 0 },
    { x: newPlank.oRect.width, y: newPlank.oRect.height },
    { x: 0, y: newPlank.oRect.height },
  ]
  dealPlankPoly(newPlank)
  newPlank.index = parseInt(Math.random() * 100000)
  newPlank.surplusIndex = newPlank.index
  newPlank.oriPlankNum = uniquePlankNum.createUniquePlankNum()
  let addressArr = []
  let idArr = []
  store.state.paibanData.forEach((e) => {
    e.parts.forEach((part) => {
      if (part.address && !addressArr.includes(part.address)) {
        addressArr.push(part.address)
      }
      if (part.orderNo && !idArr.includes(part.orderNo)) {
        idArr.push(part.orderNo)
      }
    })
  })
  newPlank.from_address = addressArr.join('/')
  newPlank.from_order_num = idArr.join('/')
  newPlank.plankNum = newPlank.oriPlankNum
  newPlank.partUniqueId = nanoid()
  store.commit('setPlankLableId', store.state.plankLableId + 1)
  newPlank.labelId = dealNumber(store.state.plankLableId)
  generateSimplePlankNum(newPlank)
  plank.parts.push(newPlank)
  return newPlank
}

// 余料自动化填充
export function surplusAutoFill(
  drawData,
  standardSurplus,
  nc_setting,
  user_info
) {
  ncSetting = nc_setting ? nc_setting : ncSetting
  gap = nc_setting ? nc_setting.panelSize.layoutGap : 0.1
  userInfo = user_info ? user_info : userInfo
  const checklistIDMap = new Map()
  // 玻璃切割机间隙为0 刀径为0
  if (nc_setting.glass_setting) {
    gap = 0.01
  }
  if (store.state.isCombineToLShape) {
    buryPoint({
      function_module: 'material',
      function_point: 'combineLShape',
    })
  }
  drawData.forEach((plank) => {
    if (plank.surplusInfo && plank.surplusInfo.shape == 'lshape') return
    if (plank.isLocked) {
      return
    }
    const isUseSaw = plank.parts.every((part) => part.isUseSaw)
    let diameter = isUseSaw
      ? cutKnifeRadius * 2
      : +getPlateKnifeDiameter(plank.stockKey, nc_setting)

    const common = cloneDeep(standardSurplus).map((item) => {
      let arr = [+item.width, +item.length]
      arr.kkey = item.texDir
      arr.texture = item.texture
      arr.thick = item.thick
      arr.matCode = item.matCode
      return arr
    })
    // 对横纹进行宽高互换旋转90°
    const standard_surplus = common.map((item) => {
      let arr = item
      // 横纹旋转
      if (item.kkey == 'reverse') {
        arr.reverse()
      }
      arr[0] = +arr[0] + +diameter
      arr[1] = +arr[1] + +diameter
      // 玻璃切割机补偿设置
      if (
        nc_setting.glass_setting &&
        nc_setting.glass_setting.contour_size_recoup
      ) {
        const recoup = nc_setting.glass_setting.contour_size_recoup
        arr[0] += recoup
        arr[1] += recoup
      }
      // xy互换模板旋转
      if (ncSetting.xyReverse) {
        arr.reverse()
      }
      return arr
    })
    // 筛选可以使用的模板
    const use_standard_surplus = checkTempOrPlank(standard_surplus, plank)
    let diameterR = diameter / 2
    if (plank.margin.toString().includes(',')) {
      const edgeArr = plank.margin.toString().split(',')
      edgeFront = Number(edgeArr[0]) - diameterR
      edgeBack = Number(edgeArr[1]) - diameterR
      edgeLeft = Number(edgeArr[2]) - diameterR
      edgeRight = Number(edgeArr[3]) - diameterR
    } else {
      edgeFront = Number(plank.margin) - diameterR
      edgeBack = Number(plank.margin) - diameterR
      edgeLeft = Number(plank.margin) - diameterR
      edgeRight = Number(plank.margin) - diameterR
    }
    // edge = store.state.historyPlankEdgeOff - diameterR
    const stock_width = plank.plankWidth - Math.abs(edgeRight)
    const stock_height = plank.plankHeight - Math.abs(edgeBack)
    let parts = plank.parts
    let part_rects = []
    for (let part of parts) {
      const p_x = part.startX
      const p_y = part.startY
      const p_w = part.rect.width
      const p_h = part.rect.height
      let p_rect = [p_x, p_y, p_x + p_w, p_y + p_h]
      part_rects.push(p_rect)
    }
    let surplus_rect = cal_surplus(
      stock_width,
      stock_height,
      part_rects,
      use_standard_surplus,
      gap
    )
    surplus_rect.forEach((point) => {
      let [x1, y1, x2, y2] = point
      let width = x2 - x1
      let height = y2 - y1
      // 获取纹理当前板件的纹理
      let cut = use_standard_surplus.find(
        (item) =>
          (Math.abs(item[0] - width) < 0.1 &&
            Math.abs(item[1] - height) < 0.1) ||
          (Math.abs(item[0] - height) < 0.1 && Math.abs(item[1] - width) < 0.1)
      )
      let rect = {
        startX: x1,
        startY: y1,
        width,
        height,
      }
      let checklistID = null
      const key = `${width.toFixed()}*${height.toFixed()}`
      if (checklistIDMap.has(key)) {
        checklistID = checklistIDMap.get(key)
      } else {
        checklistID = dealChecklistID(parts)
        checklistIDMap.set(key, checklistID)
      }
      addNewSurplus(rect, plank, cut.kkey, checklistID, isUseSaw)
      calcBigpartArea(plank, ncSetting)
    })
    if (store.state.isCombineToLShape) {
      const newParts = cutLShapePart(plank)
      plank.parts = newParts
    }
  })
}

function cal_surplus(
  stock_width,
  stock_height,
  part_rects,
  standard_surplus,
  gap
) {
  standard_surplus = standard_surplus.sort((a, b) => b[0] * b[1] - a[0] * a[1])
  let all_rect = new Set()
  all_rect.add([edgeLeft, edgeFront, stock_width, stock_height])
  for (let rect of part_rects) {
    put_rect_in_zone(rect, all_rect, standard_surplus)
  }
  let res = []
  for (let sur_rect of standard_surplus) {
    while (true) {
      let a_rect = find_available_rect(sur_rect, all_rect)
      if (!a_rect) {
        break
      }
      put_rect_in_zone(a_rect, all_rect, standard_surplus)
      res.push(a_rect)
    }
  }
  return res
}

function put_rect_in_zone(rect, zone_rect, standard_surplus) {
  let need_add_zone = []
  let need_del_zone = []
  let [rx1, ry1, rx2, ry2] = rect
  for (let tz of zone_rect) {
    let _intersect = is_rect_intersect(tz, rect)
    if (!_intersect) {
      if (!check_rect_able(tz, standard_surplus)) {
        need_del_zone.push(tz)
      }
      continue
    }
    let rect_left = [tz[0], tz[1], round(rx1 - gap, 3), tz[3]]
    let rect_right = [round(rx2 + gap, 3), tz[1], tz[2], tz[3]]
    let rect_top = [tz[0], round(ry2 + gap, 3), tz[2], tz[3]]
    let rect_bottom = [tz[0], tz[1], tz[2], round(ry1 - gap, 3)]
    if (
      is_rect_valid(rect_left) &&
      check_rect_able(rect_left, standard_surplus)
    ) {
      need_add_zone.push(rect_left)
    }
    if (
      is_rect_valid(rect_right) &&
      check_rect_able(rect_right, standard_surplus)
    ) {
      need_add_zone.push(rect_right)
    }
    if (
      is_rect_valid(rect_top) &&
      check_rect_able(rect_top, standard_surplus)
    ) {
      need_add_zone.push(rect_top)
    }
    if (
      is_rect_valid(rect_bottom) &&
      check_rect_able(rect_bottom, standard_surplus)
    ) {
      need_add_zone.push(rect_bottom)
    }
    need_del_zone.push(tz)
  }
  for (let tz of need_del_zone) {
    zone_rect.delete(tz)
  }
  for (let tz of need_add_zone) {
    let is_contains = false
    for (let az of Array.from(zone_rect)) {
      is_contains = is_rect_contains(az, tz)
      if (is_contains) {
        break
      }
    }
    if (!is_contains) {
      zone_rect.add(tz)
    }
  }
}

function find_available_rect(rect, all_rect) {
  let [width, height] = rect
  let minx = 9999999,
    miny = 9999999
  let final_rect = null
  for (let ar of all_rect) {
    let [x1, y1, x2, y2] = ar
    let w = x2 - x1
    let h = y2 - y1
    let fitness = 0
    let cur_rect = null
    if (width <= w && height <= h) {
      fitness = Math.max(width / w, height / h)
      cur_rect = [x1, y1, x1 + width, y1 + height]
    }
    // 会按照最优排 不会管纹理
    if (rect.kkey && rect.kkey == 'notcare') {
      if (width <= h && height <= w) {
        let cur_fitness = Math.max(width / h, height / w)
        if (cur_fitness > fitness) {
          cur_rect = [x1, y1, x1 + height, y1 + width]
        }
      }
    }
    if (cur_rect) {
      if (minx > x1) {
        final_rect = cur_rect
        minx = x1
        miny = y1
      } else if (minx == x1 && miny > y1) {
        final_rect = cur_rect
        minx = x1
        miny = y1
      }
    }
  }
  return final_rect
}

function check_rect_able(rect, standard_surplus) {
  if (standard_surplus.length <= 0) {
    return true
  }

  for (const s_rect of standard_surplus) {
    let [w, h] = s_rect
    if (w <= rect[2] - rect[0] && h <= rect[3] - rect[1]) {
      return true
    }
    if (h <= rect[2] - rect[0] && w <= rect[3] - rect[1]) {
      return true
    }
  }
  return false
}

function is_rect_intersect(rect_first, rect_second) {
  let [x01, y01, x02, y02] = rect_first
  let [x11, y11, x12, y12] = rect_second
  let zx = Math.abs(x01 + x02 - x11 - x12)
  let x = Math.abs(x01 - x02) + Math.abs(x11 - x12)
  let zy = Math.abs(y01 + y02 - y11 - y12)
  let y = Math.abs(y01 - y02) + Math.abs(y11 - y12)
  if (zx < x && zy < y) {
    return true
  } else {
    return false
  }
}

function is_rect_valid(rect) {
  if (rect[0] >= rect[2] || rect[1] >= rect[3]) {
    return false
  }
  return true
}

function is_rect_contains(rect_first, rect_second) {
  let [x01, y01, x02, y02] = rect_first
  let [x11, y11, x12, y12] = rect_second
  if (x11 >= x01 && x12 <= x02 && y11 >= y01 && y12 <= y02) {
    return true
  }
  return false
}

function round(num, fixed) {
  Math.round((num + Number.EPSILON) * 100) / 100
  return parseFloat(num.toFixed(fixed))
}

export function dealChecklistID() {
  const parts = store.state.paibanData.map((it) => it.parts).flat()
  if (!parts.length) return 1
  const res = parts.filter((it) => it.checklistID)
  if (!res.length) return 1
  return Number(Math.max(...res.map((it) => it.checklistID))) + 1
}
// 余料裁剪
export function tailorSurplus(drawData) {
  const {
    surplusAutoTailor,
    surplusTailorWay,
    ncSetting,
    userInfo,
    surplusCommonSize,
    isCombineToLShape,
  } = store.state
  return new Promise((resolve) => {
    if (surplusAutoTailor) {
      const data = drawData.map((item) => item.data).flat(2)
      // 余料最大矩形裁剪
      if (surplusTailorWay == 'maxRect') {
        if (isCombineToLShape) {
          buryPoint({
            function_module: 'material',
            function_point: 'combineLShape',
          })
        }
        data.forEach((stockPlank) => {
          if (stockPlank.isLocked) {
            return
          }
          surplusMaxtailor(stockPlank, ncSetting, userInfo)
          if (isCombineToLShape) {
            const newParts = cutLShapePart(stockPlank)
            stockPlank.parts = newParts
          }
        })
      }
      if (surplusTailorWay == 'commonRect') {
        surplusAutoFill(data, surplusCommonSize, ncSetting, userInfo)
      }
      uniquePlankNum.plankNumCollect.clear()
      // 重新设定下刀点和切割顺序
      calcCutPositionOrder(data, ncSetting, userInfo).then((res) => {
        resolve(res)
      })
    } else {
      resolve(false)
    }
  })
}

/** 组合大板中的 L 形余料 */
export function cutLShapePart(bigPart) {
  const surplusList = bigPart.parts.filter((part) => part.surplusIndex)
  if (surplusList.length && surplusList.length > 1) {
    const combinePartList = dealRectToLShape(surplusList)
    const newParts = bigPart.parts
      .filter((part) => {
        return !combinePartList.some((combinePart) => {
          return combinePart.__combinePartUniqueIdList.includes(
            part.partUniqueId
          )
        })
      })
      .concat(combinePartList)
    // bigPart.parts = newParts
    return newParts
  } else {
    return bigPart.parts
  }
}

function dealRectToLShape(surplusList) {
  const sortPartList = surplusList
    .sort((part1, part2) => part1.startY - part2.startY)
    .map((p, idx) => ({ ...p, sid: idx + 1 }))

  /** 所有的组合情况，为二维数组[ [part1, part2], [part3, part4], ... ] */
  const combineList = []
  sortPartList.forEach((part1, index) => {
    const resetPartList = sortPartList.slice(index + 1)
    for (let idx = 0; idx < resetPartList.length; idx++) {
      const part2 = resetPartList[idx]
      if (checkCombined(part1, part2, ncSetting.panelSize.layoutGap)) {
        combineList.push([part1, part2])
      }
    }
  })
  /** 所有可得到的 L 形组合情况，为三维数组[ [ [part1,part2],[part3,part4] ],[ [part2,part3] ], ... ] */
  const resultList = []
  /** 根据取不同的L形组合来得到所有的最终组合情况 */
  for (let idx = 0; idx < combineList.length; idx++) {
    getMostLShape(combineList.slice(idx), [combineList[idx]], resultList)
  }

  if (!resultList.length) return []

  /** 计算每种情况下的面积 */
  const areaResList = resultList.map((res) => {
    let area = 0
    res.forEach((item) => {
      area += calcCombineArea(item[0], item[1])
    })
    return area
  })
  /** 最大面积 */
  const maxArea = Math.max(...areaResList)
  const maxAreaIndex = areaResList.indexOf(maxArea)
  /** 最大面积的组合 L 型情况，为二维数组 [ [part1,part2],[part3,part4], ...] */
  const maxResultCombined = resultList[maxAreaIndex]
  /** 组合为 L 形，为一维数组 [L1,L2,...] */
  const resultPartList = maxResultCombined.map((combine) => {
    const part = combineShapeToL(combine[0], combine[1])
    return part
  })

  return resultPartList
}

function roundNumber(num, fixed = 2) {
  return Number(Number(num).toFixed(fixed))
}
/** 用于判断两个小板能否组合为 L 形 */
function checkCombined(part1, part2, layoutGap = 0.1) {
  const { startX: px1, startY: py1, rect: rectP1 } = part1
  const { startX: px2, startY: py2, rect: rectP2 } = part2

  /** 小数保留位数 */
  const fixed = ncSetting
  const x1 = roundNumber(px1, 2)
  const y1 = roundNumber(py1, 2)
  const x2 = roundNumber(px2, 2)
  const y2 = roundNumber(py2, 2)
  const rect1 = {
    width: roundNumber(rectP1.width, 2),
    height: roundNumber(rectP1.height, 2),
  }
  const rect2 = {
    width: roundNumber(rectP2.width, 2),
    height: roundNumber(rectP2.height, 2),
  }

  // 是否 Y 对齐
  const isStartYAlign =
    rect1.height !== rect2.height &&
    (roundNumber(y2 + rect2.height, 2) == roundNumber(y1 + rect1.height, 2) ||
      (y1 < y2
        ? y2 == roundNumber(y1 + rect1.height + layoutGap, 2)
        : y1 > y2
        ? y1 == roundNumber(y2 + rect2.height + layoutGap, 2)
        : true))

  // 是否 X 对齐
  const isStartXAlign =
    rect1.width !== rect2.width &&
    (roundNumber(x2 + rect2.width, 2) == roundNumber(x1 + rect1.width, 2) ||
      (x1 < x2
        ? x2 == roundNumber(x1 + rect1.width + layoutGap, 2)
        : x1 > x2
        ? x1 == roundNumber(x2 + rect2.width + layoutGap)
        : true))

  return isStartYAlign && isStartXAlign
}

/** 计算组合面积 */
function calcCombineArea(part1, part2) {
  const area1 = part1.rect.width * part1.rect.height
  const area2 = part2.rect.width * part2.rect.height
  return area1 + area2
}

/** 组合成 L 形 */
function combineShapeToL(part1, part2) {
  const { startX: x1, startY: y1, rect: rect1 } = part1
  const { startX: x2, startY: y2, rect: rect2 } = part2
  const startX = Math.min(x1, x2)
  const startY = Math.min(y1, y2)
  // 是否水平组合
  const isHCombine = y1 == y2 || y1 + rect1.height == y2 + rect2.height
  // 是否垂直组合
  const isVCombine = x1 == x2 || x1 + rect1.width == x2 + rect2.width
  const rect = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  }
  const path = []
  // 左右结构(水平)组合
  if (isHCombine) {
    if (x1 < x2) {
      rect.width = x2 + rect2.width - x1
    } else {
      rect.width = x1 + rect1.width - x2
    }
    rect.height = Math.max(rect1.height, rect2.height)
    // 上对齐左右结构
    if (y1 == y2) {
      // 左上角
      path.push({ x: 0, y: 0 })
      // 右上角
      path.push({ x: rect.width, y: 0 })
      // 右下角
      if (x2 > x1) {
        path.push({ x: rect.width, y: rect2.height })
        if (rect2.height > rect1.height) {
          path.push({ x: rect.width - rect2.width, y: rect2.height })
          path.push({ x: rect.width - rect2.width, y: rect1.height })
        } else {
          path.push({ x: rect1.width, y: rect2.height })
          path.push({ x: rect1.width, y: rect1.height })
        }
        path.push({ x: 0, y: rect1.height })
      } else {
        path.push({ x: rect.width, y: rect1.height })
        if (rect1.height > rect2.height) {
          path.push({ x: rect.width - rect1.width, y: rect1.height })
          path.push({ x: rect.width - rect1.width, y: rect2.height })
        } else {
          path.push({ x: rect2.width, y: rect1.height })
          path.push({ x: rect2.width, y: rect2.height })
        }
        path.push({ x: 0, y: rect2.height })
      }
      path.push({ x: 0, y: 0 })
    } else {
      // 下对齐左右结构
      if (x2 > x1) {
        if (rect2.height > rect1.height) {
          path.push({ x: 0, y: rect.height - rect1.height })
          path.push({
            x: rect.width - rect2.width,
            y: rect.height - rect1.height,
          })
          path.push({ x: rect.width - rect2.width, y: 0 })
          path.push({ x: rect.width, y: 0 })
          path.push({ x: rect.width, y: rect.height })
          path.push({ x: 0, y: rect.height })
          path.push({ x: 0, y: rect.height - rect1.height })
        } else {
          path.push({ x: 0, y: 0 })
          path.push({ x: rect1.width, y: 0 })
          path.push({ x: rect1.width, y: rect.height - rect2.height })
          path.push({ x: rect.width, y: rect.height - rect2.height })
          path.push({ x: rect.width, y: rect.height })
          path.push({
            x: 0,
            y: rect1.height,
          })
          path.push({ x: 0, y: 0 })
        }
      } else {
        if (rect1.height > rect2.height) {
          path.push({ x: 0, y: rect.height - rect2.height })
          path.push({
            x: rect.width - rect1.width,
            y: rect.height - rect2.height,
          })
          path.push({ x: rect.width - rect1.width, y: 0 })
          path.push({ x: rect.width, y: 0 })
          path.push({ x: rect.width, y: rect.height })
          path.push({ x: 0, y: rect.height })
          path.push({ x: 0, y: rect.height - rect2.height })
        } else {
          path.push({ x: 0, y: 0 })
          path.push({ x: rect2.width, y: 0 })
          path.push({ x: rect2.width, y: rect.height - rect1.height })
          path.push({ x: rect.width, y: rect.height - rect1.height })
          path.push({ x: rect.width, y: rect.height })
          path.push({ x: 0, y: rect.height })
          path.push({ x: 0, y: 0 })
        }
      }
    }
  }
  // 上下结构(垂直)组合
  if (isVCombine) {
    if (y1 < y2) {
      rect.height = y2 + rect2.height - y1
    } else {
      rect.height = y1 + rect1.height - y2
    }
    rect.width = Math.max(rect1.width, rect2.width)
    if (x1 == x2) {
      if (y2 > y1) {
        if (rect2.width > rect1.width) {
          path.push({ x: 0, y: 0 })
          path.push({ x: rect1.width, y: 0 })
          path.push({ x: rect1.width, y: rect.height - rect2.height })
          path.push({ x: rect.width, y: rect.height - rect2.height })
          path.push({ x: rect.width, y: rect.height })
          path.push({ x: 0, y: rect.height })
          path.push({ x: 0, y: 0 })
        } else {
          path.push({ x: 0, y: 0 })
          path.push({ x: rect.width, y: 0 })
          path.push({ x: rect.width, y: rect1.height })
          path.push({ x: rect2.width, y: rect1.height })
          path.push({ x: rect2.width, y: rect.height })
          path.push({ x: 0, y: rect.height })
          path.push({ x: 0, y: 0 })
        }
      } else {
        if (rect1.width > rect2.width) {
          path.push({ x: 0, y: 0 })
          path.push({ x: rect.width, y: 0 })
          path.push({ x: rect.width, y: rect1.height })
          path.push({ x: rect2.width, y: rect1.height })
          path.push({ x: rect2.width, y: rect.height })
          path.push({ x: 0, y: rect.height })
          path.push({ x: 0, y: 0 })
        } else {
          path.push({ x: 0, y: 0 })
          path.push({ x: rect1.width, y: 0 })
          path.push({ x: rect1.width, y: rect.height - rect2.height })
          path.push({ x: rect.width, y: rect.height - rect2.height })
          path.push({ x: rect.width, y: rect.height })
          path.push({ x: 0, y: rect.height })
          path.push({ x: 0, y: 0 })
        }
      }
    } else {
      if (y2 > y1) {
        if (rect2.width > rect1.width) {
          path.push({ x: 0, y: rect.height - rect2.height })
          path.push({
            x: rect.width - rect1.width,
            y: rect.height - rect2.height,
          })
          path.push({ x: rect.width - rect1.width, y: 0 })
          path.push({ x: rect.width, y: 0 })
          path.push({ x: rect.width, y: rect.height })
          path.push({ x: 0, y: rect.height })
          path.push({ x: 0, y: rect.height - rect2.height })
        } else {
          path.push({ x: 0, y: 0 })
          path.push({ x: rect.width, y: 0 })
          path.push({ x: rect.width, y: rect.height })
          path.push({ x: rect.width - rect2.width, y: rect.height })
          path.push({ x: rect.width - rect2.width, y: rect1.height })
          path.push({ x: 0, y: rect1.height })
          path.push({ x: 0, y: 0 })
        }
      } else {
        if (rect1.width > rect2.width) {
          path.push({ x: 0, y: rect.height - rect1.height })
          path.push({
            x: rect.width - rect2.width,
            y: rect.height - rect1.height,
          })
          path.push({ x: rect.width - rect2.width, y: 0 })
          path.push({ x: rect.width, y: 0 })
          path.push({ x: rect.width, y: rect.height })
          path.push({ x: 0, y: rect.height })
          path.push({ x: 0, y: rect.height - rect1.height })
        } else {
          path.push({ x: 0, y: 0 })
          path.push({ x: rect.width, y: 0 })
          path.push({ x: rect.width, y: rect.height })
          path.push({ x: rect.width - rect1.width, y: rect.height })
          path.push({ x: rect.width - rect1.width, y: rect2.height })
          path.push({ x: 0, y: rect2.height })
          path.push({ x: 0, y: 0 })
        }
      }
    }
  }
  const polyArr = path.map((point) => {
    return {
      X: point.x + startX,
      Y: point.y + startY,
    }
  })
  const plankNum = uniquePlankNum.createUniquePlankNum()
  const partUniqueId = nanoid()
  const rectToPath = [
    { x: 0, y: 0 },
    { x: rect.width, y: 0 },
    { x: rect.width, y: rect.height },
    { x: 0, y: rect.height },
  ]

  const knifeDia = rect1.height - part1.realRect.height
  const oRect = {
    ...rect,
    width: rect.width - knifeDia,
    height: rect.height - knifeDia,
  }

  return {
    ...part1,
    startX,
    startY,
    rect,
    realRect: rect,
    path: [path],
    surplusPath: [path],
    oRect,
    realRect: oRect,
    fullSize: rect,
    partUniqueId,
    oriPartUniqueId: partUniqueId,
    __combinePartUniqueIdList: [part1.partUniqueId, part2.partUniqueId],
    polyArr: [polyArr],
    realCurve: rectToPath,
    plankNum,
    oriPlankNum: plankNum,
  }
}

/** 获取所有 L 形组合情形 */
function getMostLShape(combineList, singleResult = [], allResult = []) {
  /** 记录已经取了的sid */
  const map = {}
  for (let i in singleResult) {
    map[singleResult[i][0].sid] = 1
    map[singleResult[i][1].sid] = 1
  }
  // 筛选掉已经组合过的
  const resetCombineList = combineList.filter((shape) => {
    const sidList = shape.map((i) => i.sid)
    return !map[sidList[0]] && !map[sidList[1]]
  })

  /** 地柜结束，将结果放入最终数据 */
  if (!resetCombineList.length) {
    allResult.push(singleResult)
  }

  for (let i = 0; i < resetCombineList.length; i++) {
    let item = resetCombineList[i]
    let newSingleResult = [...singleResult]
    newSingleResult.push(item)
    getMostLShape(resetCombineList, newSingleResult, allResult)
  }
}

function checkTempOrPlank(temp, plank) {
  const {
    matCode: plankMatCode,
    texture: plankTexture,
    thick: plankThick,
  } = plank
  const newTemp = temp.filter((it) => {
    const { matCode: tempMatCode, texture: tempTexture, thick: tempThick } = it
    let matCodeFlag = true,
      textureFlag = true,
      thickFlag = true
    if (tempMatCode && tempMatCode != plankMatCode) matCodeFlag = false
    if (tempTexture && tempTexture != plankTexture) textureFlag = false
    if (+tempThick && +tempThick != +plankThick) thickFlag = false
    return matCodeFlag && textureFlag && thickFlag
  })
  return newTemp
}
