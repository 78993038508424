/**
 * 绘制单张大板
 */
import { CODE_PAUSE } from '@/data/constant'
import { DrawBigPlankParams } from '@/data/plank'
import { bigPlankLockImage } from '@/data/plank'
import { IBigPlank, PointType } from '@/partTypes'
import { translate as t } from '@/util/commonFun'
import { calcPlankSize, rectToPath } from '@/util/commonFuncs'

/** 顶部文字信息 */
type TopInfoSingle = {
  text: string
  lineHeight: number
  type?: BoxType
  gap?: number
  before?: TopInfoSingle
  after?: TopInfoSingle
}

/**
 * 规定大板单个部位绘制完成后的返回值类型,可用于后期重构画布点击激活以及相应等相关逻辑
 */
interface DrawBoxReturnType {
  /** 此盒子绘制的类型 */
  type: BoxType
  /** 大板绘制起点 x */
  plankStartX: number
  /** 大板绘制起点 y */
  plankStartY: number
  /** 绘制的路径,需要是页面显示路径，也就是加上了 startX startY*/
  path: PointType[]
  /** 当前大板 */
  plank: IBigPlank
}

/** 各个边界盒子的类型,在相应盒子触发相应事件时会返回此参数 */
enum BoxType {
  /** 顶部余料库名称 */
  'topInfo:surplusStoreName' = 'topInfo:surplusStoreName',
  /** 顶部余料圆圈标识 */
  'topInfo:surplusCircle' = 'topInfo:surplusCircle',
  /** 锁定图案 */
  'lock:lock' = 'lock:lock',
  /** 顶部单面板圆圈标识 */
  'topInfo:toggleFlag' = 'topInfo:toggleFlag',
}
/**
 * @description 一张大板的所有绘制逻辑，此类只关注绘制，如何绘制由外部决定
 * 除边框以外的其他绘制逻辑的起点均是按照边框的绘制起点来定的，和之前不同
 */
export class BigPlankDrawer {
  /** @description 是否为活跃大板 */
  isActive = false
  images = bigPlankLockImage
  startX?: number
  startY?: number
  width?: number
  height?: number
  constructor(
    public bigPlank: IBigPlank,
    public ncSetting: any,
    public defaultScale: number,
    public scale: number
  ) {}
  /** @description 设置绘制起点，起点是基于大板边框的（特殊情况才会用到） */
  setStart(startX: number, startY: number) {
    this.startX = startX
    this.startY = startY
  }
  /** @description 获取起点 */
  private getStart() {
    return {
      x: this.startX ?? this.bigPlank.startX,
      y: this.startY ?? this.bigPlank.startY,
    }
  }
  /** @description 设置大板大小（特殊情况才会用到） */
  setSize(width: number, height: number) {
    this.width = width
    this.height = height
  }
  /** @description 获取大板大小 */
  private getSize() {
    return {
      width: this.width ?? this.bigPlank.width,
      height: this.height ?? this.bigPlank.height,
    }
  }
  /** @description 获取边框的绘制起点，为了后续的计算好处理一点 */
  getMarginStart() {
    const { x, y } = this.getStart()
    return {
      x,
      y: y + this.scaleFunc(DrawBigPlankParams.plankMarginY / 2),
    }
  }
  /** @description 获取大板绘制起点 */
  getContainerStart() {
    const { x, y } = this.getMarginStart()
    return {
      x: x + this.scaleFunc(DrawBigPlankParams.margin),
      y:
        y +
        this.scaleFunc(DrawBigPlankParams.toggleHeight) +
        this.scaleFunc(DrawBigPlankParams.margin),
    }
  }
  /** @description 绘制大板顶部信息 */
  drawTopInfo(ctx: CanvasRenderingContext2D) {
    const { x: startX, y: startY } = this.getMarginStart()
    const { topInfoTextColor, topInfoMargin, topInfoLineGap } =
      DrawBigPlankParams
    ctx.save()
    // 大板顶部所有文字基线均按照中心来处理，之后的处理中需要考虑文字是按照中心点绘制的，x 轴无影响
    ctx.textBaseline = 'middle'
    ctx.fillStyle = topInfoTextColor
    const fontSize = this.scaleFunc(14)
    ctx.font = `bold ${fontSize}px 'PingFangSC-Regular, PingFang SC'`
    /** 起始 y */
    let y = startY - this.scaleFunc(topInfoMargin)
    this.getTopInfo(ctx).forEach((item) => {
      const maxLineHeight = Math.max(
        item.lineHeight,
        item.after?.lineHeight ?? 0,
        item.before?.lineHeight ?? 0
      )
      /** 起始 x 每一行都是重新计算 */
      let x = startX
      // 绘制的起始位置
      const newY = y - this.scaleFunc(maxLineHeight)
      const centerY = (y + newY) / 2
      y = newY - this.scaleFunc(topInfoLineGap)
      // 核心文字之前的绘制
      if (item.before) {
        // 如果绘制类型是余料信息 如果后续有其他的则建议抽离成对应的方法以及采用 switch，目前只有一个则不进行复杂的处理
        if (item.before.type === BoxType['topInfo:surplusCircle']) {
          ctx.strokeStyle = '#000'
          ctx.lineWidth = 2
          const radius = this.scaleFunc(item.before.lineHeight)
          // 因为是中心点需要加上半径
          x += radius
          drawCircleTextByMiddle(
            ctx,
            item.before?.text ?? '',
            x,
            centerY,
            radius
          )
          // 嗯就是这个意思
          x += radius + (item.before.gap ?? 0)
        }
      }
      ctx.fillText(item.text, x, centerY)
      // 核心文字之后的绘制
      // 目前没有就不写了如果有 x += measureText(text).width 然后照常绘制即可
    })
    ctx.restore()
  }
  /** @description 获取顶部要绘制的信息 */
  getTopInfo(ctx: CanvasRenderingContext2D) {
    const {
      canvasIndex,
      usedRate,
      plankHeight,
      plankWidth,
      surplusInfo,
      margin,
    } = this.bigPlank
    const { width } = this.getMarginSize()
    const { topInfoHeight, topInfoSurplusCircleR } = DrawBigPlankParams
    /** 板件序号信息 */
    const index = `【${canvasIndex}】`
    /** 优化率 */
    const rate = `${((usedRate > 1 ? 1 : usedRate) * 100).toFixed(2)}%-`
    //  大板修边
    const bigpartMargin = margin?.toString().includes(',')
      ? (margin as string).split(',')[0]
      : margin
    /** 板件尺寸信息 */
    let size = `${plankWidth}X${plankHeight}-${bigpartMargin}`
    const { shape, isNotSurplus, branch_name } = surplusInfo ?? {}
    /** 是否是余料 */
    const isSurplus = surplusInfo && !isNotSurplus
    const isLSurplus = shape === 'lshape'
    /** L 型余料板件尺寸信息显示样式不同 */
    if (surplusInfo && isLSurplus) {
      const {
        longH = 0,
        longW = 0,
        shortH = 0,
        shortW = 0,
      } = calcPlankSize(this.bigPlank, true)
      size = `[${longW}(${shortW})X${longH}(${shortH})]-${bigpartMargin}`
    }
    /** 是否需要换行显示优化率后面的尺寸信息，当[余]+序号+优化率+' '+尺寸在一行时宽度超过大板绘制宽度则将尺寸信息单独绘制一行 */
    const isLineBreak =
      ctx.measureText(`${index}${rate} ${size}`).width +
        // 余料的情况下需要考虑上余料本身绘制的圆圈宽度
        (isSurplus ? topInfoSurplusCircleR * 2 : 0) >
      width
    /** 余料库名称 */
    let surplusStoreName = branch_name === '-' ? '' : branch_name ?? ''
    if (surplusStoreName) {
      let addEllipsis = false
      /** 余料库名称超过绘制板宽则需要用省略号代替超出部分 */
      while (ctx.measureText(surplusStoreName).width >= width) {
        surplusStoreName = surplusStoreName.slice(0, -1)
        addEllipsis = true
      }
      if (addEllipsis) {
        surplusStoreName = surplusStoreName + '...'
      }
    }
    // 最终结果受顺序影响
    const result: TopInfoSingle[] = [
      {
        before: isSurplus
          ? {
              text: '余',
              type: BoxType['topInfo:surplusCircle'],
              lineHeight: topInfoSurplusCircleR as number,
            }
          : undefined,
        text: `${index}${rate} ${isLineBreak ? '' : size}`,
        lineHeight: topInfoHeight as number,
      },
    ]
    if (isLineBreak) {
      result.unshift({
        text: size,
        lineHeight: topInfoHeight as number,
      })
    }
    if (surplusStoreName) {
      result.unshift({
        text: `${surplusStoreName}`,
        lineHeight: topInfoHeight as number,
        type: BoxType['topInfo:surplusStoreName'],
      })
    }
    return result
  }
  /** @description 绘制大板切换正反以及单面板 */
  drawToggle(ctx: CanvasRenderingContext2D, engravingRectArr?: any[]) {
    // 设置字体大小
    const fontSize = this.scaleFunc(14)
    ctx.font = `bold ${fontSize}px 'PingFangSC-Regular, PingFang SC' `
    const { ableToggle, sideType, noEngraving } = this.bigPlank
    const { x: startX, y: startY } = this.getMarginStart()
    // 宽度需要使用边框的宽度
    const { width: marginWidth, lineWidth } = this.getMarginSize()
    // 需要减去边框的宽度才能是此处需要的真正的宽度
    const width = marginWidth - (lineWidth as number) * 2
    const { toggleHeight } = DrawBigPlankParams
    /** 头部高度 */
    const scaleToggleHeight = this.scaleFunc(toggleHeight)
    /** 头部文字 y 值魔法数字 */
    const toggleTextY = this.scaleFunc(22)
    /** 圆圈 padding */
    const circlePadding = this.scaleFunc(5)
    // 需要绘制的内容
    interface DrawType {
      text: string
      x: number
      y: number
      width: number
      bgColor: string
      color: string
      after?: Omit<DrawType, 'x' | 'y' | 'width'>
      before?: Omit<DrawType, 'x' | 'y' | 'width'>
      margin?: number
      isNeedCircle?: boolean
    }
    const draws: DrawType[] = []

    if (!ableToggle) {
      // 单面板
      const singlePlankDrawObj: DrawType = {
        text: t('common.singlePlank'),
        x: startX,
        y: startY,
        width,
        bgColor: 'rgba(255, 255, 255, 0.9)',
        color: 'rgba(0, 0, 0, 0.6)',
      }

      draws.push(
        // 单面板的底色
        {
          text: '',
          x: startX,
          y: startY,
          width,
          bgColor: '#cfcfcf',
          color: '#fff',
        }
      )
      if (noEngraving) {
        Object.assign(singlePlankDrawObj, {
          after: {
            text: '雕',
            x: startX + width / 2 + 12,
            y: startY,
            width: width / 10,
            color: 'rgba(247, 158, 19)',
            bgColor: 'rgba(255, 255, 255, 0.9)',
            isNeedCircle: true,
            margin: 8,
          },
        })
        engravingRectArr?.push({
          x: startX + width / 2 + 20,
          y: startY,
          width: width / 10 + circlePadding,
          height: width / 10 + circlePadding,
        })
      }
      draws.push(singlePlankDrawObj)
    } else {
      const activeBgColor = '#18a8c7'
      const unActiveBgColor = '#fff'
      const activeColor = '#fff'
      const unActiveColor = 'rgba(0, 0, 0, 0.6)'
      const drawObjFront = {
        text: t('common.front'),
        x: startX,
        y: startY,
        width: width / 2,
        bgColor: sideType === 1 ? activeBgColor : unActiveBgColor,
        color: sideType === 1 ? activeColor : unActiveColor,
      }
      const drawObjBack = {
        text: t('common.back'),
        x: startX + width / 2,
        y: startY,
        width: width / 2,
        bgColor: sideType === -1 ? activeBgColor : unActiveBgColor,
        color: sideType === -1 ? activeColor : unActiveColor,
      }
      draws.push(drawObjFront, drawObjBack)
    }

    function drawFunc(draw: DrawType) {
      const {
        x,
        y,
        text,
        bgColor,
        color,
        width: itemWidth,
        isNeedCircle,
      } = draw
      const { width: textWidth } = ctx.measureText(text)
      // 绘制矩形框
      ctx.fillStyle = bgColor
      ctx.fillRect(x, y, itemWidth, scaleToggleHeight)
      // 绘制文字
      ctx.fillStyle = color
      ctx.fillText(
        text,
        x + (itemWidth / 2 - textWidth / 2),
        // 22是个魔法数字
        y + toggleTextY
      )
      if (isNeedCircle) {
        ctx.fillStyle = color
        ctx.strokeStyle = color
        /** 圆圈半径 */
        const radius = textWidth / 2 + circlePadding
        // 绘制圆圈
        ctx.beginPath()
        // 1 为魔法数字，就是要才能居中 你懂的
        ctx.arc(
          x + (itemWidth / 2 - textWidth / 2) + circlePadding + 1.5,
          y + toggleTextY / 2 + circlePadding + 1.5,
          radius,
          0,
          Math.PI * 2
        )
        ctx.stroke()
        ctx.closePath()
        ctx.fillStyle = 'rgba(0, 0, 0, 0.6)'
      }
    }

    draws.forEach((draw) => {
      drawFunc(draw)
      const { x, y, text, width: itemWidth, margin } = draw
      // 中心文字宽度
      const textWidth = ctx.measureText(text).width
      if (draw.after) {
        const { margin: afterMargin } = draw.after
        // 绘制文字宽度
        const afterTextWidth = ctx.measureText(draw.after.text).width
        const afterStartX =
          x + itemWidth / 2 + textWidth / 2 + this.scaleFunc(margin ?? 0)
        drawFunc({
          ...draw.after,
          x: afterStartX + (afterMargin ?? 0),
          y: y,
          width: afterTextWidth,
        })
      }
      if (draw.before) {
        const { margin: beforeMargin } = draw.before
        // 绘制文字宽度
        const beforeTextWidth = ctx.measureText(draw.before.text).width
        const beforeStartX =
          x +
          itemWidth / 2 -
          textWidth / 2 -
          beforeTextWidth -
          this.scaleFunc(margin ?? 0)
        drawFunc({
          ...draw.before,
          x: beforeStartX - (beforeMargin ?? 0),
          y: y,
          width: beforeTextWidth,
        })
      }
    })
  }
  /** @description 绘制大板边框 */
  drawMargin(ctx: CanvasRenderingContext2D) {
    const {
      normalMarginStroke,
      activeMarginStroke,
      normalMarginStrokeColor,
      activeMarginStrokeColor,
    } = DrawBigPlankParams
    const lineWidth = this.isActive ? activeMarginStroke : normalMarginStroke
    const strokeStyle = this.isActive
      ? activeMarginStrokeColor
      : normalMarginStrokeColor
    ctx.lineWidth = lineWidth
    ctx.strokeStyle = strokeStyle
    const { x, y, width, height } = this.getMarginSize()
    ctx.strokeRect(x, y, width, height)
    return
  }
  /** @description 获取边框大小 */
  getMarginSize() {
    const { normalMarginStroke, activeMarginStroke, margin, toggleHeight } =
      DrawBigPlankParams
    const lineWidth = this.isActive ? activeMarginStroke : normalMarginStroke
    const { width, height } = this.getSize()
    const { x, y } = this.getMarginStart()
    return {
      x: x - lineWidth,
      y: y - lineWidth,
      width: width + 2 * this.scaleFunc(margin) + 2 * lineWidth,
      height:
        height +
        this.scaleFunc(toggleHeight) +
        2 * this.scaleFunc(margin) +
        2 * lineWidth,
      lineWidth,
    }
  }
  /**
   * 绘制四边修边
   * @param ctx
   */
  drawFourEdge(ctx: CanvasRenderingContext2D) {
    const { x, y, width, height } = this.getMarginSize()
    if (this.bigPlank.margin?.toString().includes(',')) {
      ctx.save()
      ctx.fillStyle = '#000'
      const marginArr = (this.bigPlank.margin as string).split(',')
      // 左修边
      ctx.fillText(
        marginArr[2],
        x - (ctx.measureText(marginArr[2]).width as number) - 5,
        y + height / 2
      )
      // 下修边
      ctx.fillText(marginArr[1], x + width / 2 - 3, y + height + 17)
      // 右修边
      ctx.fillText(marginArr[3], x + width + 4, y + height / 2)
      ctx.restore()
    }
  }
  /** @description 绘制大板真正所占据的大小 */
  drawContainer(
    ctx: CanvasRenderingContext2D,
    options: {
      trimSide: string
      plankBgColor?: string
    }
  ) {
    const { margin, toggleHeight, plankBgColor } = DrawBigPlankParams
    const { x: startX, y: startY } = this.getMarginStart()
    const x = startX + this.scaleFunc(margin)
    const y = startY + this.scaleFunc(toggleHeight) + this.scaleFunc(margin)
    ctx.fillStyle = options.plankBgColor || plankBgColor
    const pathArr = genDrawBigPlankPath(
      this.bigPlank,
      this.ncSetting,
      { defaultScale: this.defaultScale, scale: this.scale, ...options },
      x,
      y
    )
    ctx.beginPath()
    pathArr.forEach(({ x, y }, idx) => {
      if (idx === 0) {
        ctx.moveTo(x, y)
      } else {
        ctx.lineTo(x, y)
      }
    })
    ctx.fill()
    ctx.closePath()
  }
  /** @description 绘制大板锁定标识 */
  drawLock(ctx: CanvasRenderingContext2D): DrawBoxReturnType {
    const { isLocked } = this.bigPlank
    const { x, y } = this.getContainerStart()
    const { width } = this.getSize()
    const size = this.scaleFunc(24)
    const startX = x + width + this.scaleFunc(15)
    const startY = y - this.scaleFunc(30)
    // 无论哪种情况都讲绘制的位置抛出
    // 激活或者已锁定的情况都需要显示
    const result: DrawBoxReturnType = {
      type: BoxType['lock:lock'],
      path: rectToPath({ x: startX, y: startY, width: size, height: size }),
      plankStartX: x,
      plankStartY: y,
      plank: this.bigPlank,
    }
    if (!this.isActive && !isLocked) return result
    const img = isLocked ? this.images.unlock : this.images.lock
    if (img) {
      ctx.drawImage(img, startX, startY, size, size)
    }
    // 返回绘制位置
    return result
  }
  /** @description 绘制更多设置 */
  drawMoreSetting(ctx: CanvasRenderingContext2D) {
    if (!this.isActive) return
    ctx.fillStyle = '#ECECEC'
    const { x: startX, width } = this.getMarginSize()
    const { y: startY } = this.getContainerStart()
    const { margin } = DrawBigPlankParams
    const x = startX + width + this.scaleFunc(margin) + this.scaleFunc(2)
    const y = startY
    const long = this.scaleFunc(40)
    const r = this.scaleFunc(2.5)
    ctx.fillRect(x, y, long, long)
    const arcY = y + this.scaleFunc(20)
    let arcX = 0
    ctx.fillStyle = '#666'
    for (let index = 0; index < 3; index++) {
      ctx.beginPath()
      ctx.arc(x + this.scaleFunc((arcX += 10)), arcY, r, 0, Math.PI * 2, true)
      ctx.closePath()
      ctx.fill()
    }
  }
  /** @description 设置活跃状态 */
  setActive(isActive: boolean) {
    this.isActive = isActive
  }
  /** @description 根据缩放比例缩放 */
  scaleFunc(num: number) {
    return num * this.scale
  }
}

/**
 * @description 返回大板绘制路径(余料以及标准大板)(目前这种写法还是很死基本属于硬编码，后续有时间需要修改成固有路径加旋转反面操作来得到其想要的缺口位置比较好)
 * @param bigPlank 大板信息
 * @param ncSetting nc 设置信息
 * @param options { defaultScale: number; scale: number } 默认缩放/动态缩放
 * @param startX 起始 x
 * @param startY 起始 y
 * @returns
 */
export function genDrawBigPlankPath(
  bigPlank: IBigPlank,
  ncSetting: any,
  options: {
    defaultScale: number
    scale: number
    trimSide: string
  } = {
    defaultScale: 1,
    scale: 1,
    trimSide: '',
  },
  startX = 0,
  startY = 0
) {
  const { surplusInfo: surplus, plankWidth, plankHeight } = bigPlank
  const { xyReverse, startPosition } = ncSetting
  const { defaultScale, scale } = options
  //todo 功能存在问题代码暂停执行
  // const { defaultScale, scale, trimSide } = options
  const nPath: PointType[] = []
  /** 缩放函数，合其他地方的绘制函数相同 */
  const scaleFunc = translateScaleByDraw(defaultScale, scale)
  /** 矩形大板绘制宽高 */
  let rectWidth = scaleFunc(plankWidth)
  let rectHeight = scaleFunc(plankHeight)
  if (surplus && Object.keys(surplus).length > 0) {
    if (surplus.shape && surplus.shape == 'lshape') {
      let x3 = xyReverse ? Number(surplus.y5) : Number(surplus.x3)
      let y3 = xyReverse ? Number(surplus.x5) : Number(surplus.y3)
      let x4 = xyReverse ? Number(surplus.y4) : Number(surplus.x4)
      let y4 = xyReverse ? Number(surplus.x4) : Number(surplus.y4)
      let x5 = xyReverse ? Number(surplus.y3) : Number(surplus.x5)
      let y5 = xyReverse ? Number(surplus.x3) : Number(surplus.y5)
      const newWidth = surplus.width
      const newHeight = surplus.height
      const sNewWidth = scaleFunc(newWidth)
      const sNewHeight = scaleFunc(newHeight)
      if (
        xyReverse &&
        (startPosition == '右下角' || startPosition == '左上角')
      ) {
        x3 = newWidth - x3
        x4 = newWidth - x4
        x5 = newWidth - x5
        nPath.push({ x: startX, y: startY })
        nPath.push({ x: startX + sNewWidth, y: startY })
        nPath.push({
          x: startX + sNewWidth,
          y: startY + sNewHeight,
        })
        nPath.push({ x: startX + scaleFunc(x3), y: startY + scaleFunc(y3) })
        nPath.push({ x: startX + scaleFunc(x4), y: startY + scaleFunc(y4) })
        nPath.push({ x: startX + scaleFunc(x5), y: startY + scaleFunc(y5) })
      } else {
        y3 = newHeight - y3
        y4 = newHeight - y4
        y5 = newHeight - y5
        // 根据修边方向(xy互换不处理，高光板只生效右下、左上修边) 设置不同缺口方向
        const isHighGPlank =
          bigPlank.parts[0]?.is_high_gloss_plank ??
          bigPlank.stockKey.includes('高光_')
        // todo 功能存在问题代码暂停执行 没问题后释放上方的代码
        const trimSide = CODE_PAUSE as any
        if (trimSide === 'topRight' && !xyReverse && !isHighGPlank) {
          nPath.push({ x: startX, y: startY + sNewHeight })
          nPath.push({
            x: startX + scaleFunc(x3),
            y: startY + sNewHeight - scaleFunc(y3),
          })
          nPath.push({
            x: startX + scaleFunc(x4),
            y: startY + sNewHeight - scaleFunc(y4),
          })
          nPath.push({
            x: startX + scaleFunc(x5),
            y: startY + sNewHeight - scaleFunc(y5),
          })
          nPath.push({
            x: startX + sNewWidth,
            y: startY,
          })
          nPath.push({
            x: startX,
            y: startY,
          })
        } else if (trimSide === 'bottomLeft' && !xyReverse && !isHighGPlank) {
          nPath.push({
            x: startX + sNewWidth,
            y: startY,
          })
          nPath.push({
            x: startX + sNewWidth - scaleFunc(x3),
            y: startY + scaleFunc(y3),
          })
          nPath.push({
            x: startX + sNewWidth - scaleFunc(x4),
            y: startY + scaleFunc(y4),
          })
          nPath.push({
            x: startX + sNewWidth - scaleFunc(x5),
            y: startY + scaleFunc(y5),
          })
          nPath.push({
            x: startX,
            y: startY + sNewHeight,
          })
          nPath.push({
            x: startX + sNewWidth,
            y: startY + sNewHeight,
          })
        } else if (trimSide === 'topLeft' && !xyReverse) {
          nPath.push({
            x: startX + sNewWidth,
            y: startY + sNewHeight,
          })
          nPath.push({
            x: startX + sNewWidth - scaleFunc(x3),
            y: startY + sNewHeight - scaleFunc(y3),
          })
          nPath.push({
            x: startX + sNewWidth - scaleFunc(x4),
            y: startY + sNewHeight - scaleFunc(y4),
          })
          nPath.push({
            x: startX + sNewWidth - scaleFunc(x5),
            y: startY + sNewHeight - scaleFunc(y5),
          })
          nPath.push({ x: startX, y: startY })
          nPath.push({ x: startX + sNewWidth, y: startY })
        } else {
          nPath.push({ x: startX, y: startY })
          nPath.push({ x: startX + scaleFunc(x3), y: startY + scaleFunc(y3) })
          nPath.push({ x: startX + scaleFunc(x4), y: startY + scaleFunc(y4) })
          nPath.push({ x: startX + scaleFunc(x5), y: startY + scaleFunc(y5) })
          nPath.push({
            x: startX + sNewWidth,
            y: startY + sNewHeight,
          })
          nPath.push({ x: startX, y: startY + sNewHeight })
        }
      }
    } else {
      rectWidth = scaleFunc(surplus.width)
      rectHeight = scaleFunc(surplus.height)
    }
  }
  /** 为矩形生成路径 */
  if (nPath.length === 0) {
    nPath.push(
      ...rectToPath({
        x: startX,
        y: startY,
        width: rectWidth,
        height: rectHeight,
      })
    )
  }
  return nPath
}

/**
 * @description 返回一个函数用于计算当前值根据指定缩放值缩放后的大小
 */
function translateScaleByDraw(defaultScale: number, scale: number) {
  return (size: number) => (size / defaultScale) * scale
}

/**
 * @description 绘制圆圈带文字，基于文字基线是 middle
 * @param ctx
 * @param text
 * @param x
 * @param y
 * @param r
 * @param scale
 */
function drawCircleTextByMiddle(
  ctx: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  r: number
) {
  ctx.beginPath()
  ctx.arc(x, y, r, Math.PI * 2, 0)
  ctx.closePath()
  ctx.stroke()
  const { width } = ctx.measureText(text)
  ctx.fillText(text, x - width / 2, y)
}

/**
 * @description 初始化绘制数据，生成每一个大板初始所在位置，整个 canvas 初始高度
 */
// 测试数据 canvas默认宽度
export function initBigPlanksPositionToCanvas(
  bigPlanks: IBigPlank[],
  scale: number,
  dWidth: number
) {
  const scaleFunc = translateScaleByDraw(6, scale)
  const {
    plankMarginY,
    plankMarginX,
    plankMarginLeft,
    plankMarginTop,
    margin,
    canvasExtraMargin,
  } = DrawBigPlankParams
  /** 记录每行行的最高行高 */
  const lineMaxHeightArr: number[] = []
  let currentLine = 0
  /** 上一个处理过的大板(用于定位下一个的位置) */
  let lastPlank: IBigPlank | null = null
  const plankEdge = margin * scale
  /** 首个大板 y */
  const firstLeft = plankMarginLeft * scale
  /** 首个大板 x */
  const firstTop = plankMarginTop * scale
  /** 两个大板纵向间隔 */
  const verticalGap = plankMarginY * scale
  /** 两个大板横向间隔 */
  const horizontalGap = plankMarginX * scale
  bigPlanks.forEach((plank, idx) => {
    const { surplusInfo, plankHeight, plankWidth } = plank
    // 生成绘制所用的宽高
    if (isSurplus(plank)) {
      plank.height = scaleFunc(surplusInfo?.height ?? 0)
      plank.width = scaleFunc(surplusInfo?.width ?? 0)
    } else {
      plank.height = scaleFunc(plankHeight ?? 0)
      plank.width = scaleFunc(plankWidth ?? 0)
    }
    // 记录每一行的最高高度
    lineMaxHeightArr[currentLine] = Math.max(
      lineMaxHeightArr[currentLine] ?? 0,
      // 需要加一个纵向的间距因为其起始位置是大板边框上plankMarginY / 2
      plank.height + verticalGap
    )
    if (idx === 0) {
      // 第一个生成初始位置
      plank.startX = firstLeft
      plank.startY = firstTop
    } else {
      const { startX: lStartX, startY: lStartY, width: lWidth } = lastPlank!
      // 如果当前大板超出一行，则换行
      if (lStartX + lWidth + plankEdge + plank.width + horizontalGap > dWidth) {
        plank.startX = firstLeft
        // 最后一张大板的底部+大板和边框边距+两个大板间距
        plank.startY =
          lStartY +
          plankEdge +
          lineMaxHeightArr[currentLine] +
          // 说明一下为什么(好想贴张图，为什么不能贴图)：因为绘制的起点是上面说的那个逻辑，所以需要知道下一个板件的绘制起点就需要在加上一半的边距
          verticalGap +
          verticalGap / 2
        // 行数+1
        currentLine++
        lineMaxHeightArr[currentLine] = plank.height + verticalGap
      } else {
        // 如果还在当前行
        plank.startX = lStartX + lWidth + horizontalGap
        plank.startY = lStartY
      }
    }

    lastPlank = plank
  })
  // 返回总高
  return (
    ((lastPlank as any)?.startY ?? 0) +
    (Math.max(...lineMaxHeightArr) ?? 0) +
    firstTop +
    canvasExtraMargin
  )
}

function isSurplus(plank: IBigPlank) {
  return plank.surplusInfo && !plank.surplusInfo.isNotSurplus
}
